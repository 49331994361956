import { Transition } from '@headlessui/react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { type Profile } from '@prisma/client'
import { useEffect } from 'react'
import toast, { resolveValue, Toaster } from 'react-hot-toast'
import { useEventSource } from 'remix-utils/sse/react'
import { eventName_notify, type NotifyData } from '#app/utils/sse.js'

export function toastInfo(data: NotifyData) {
	toast.custom(
		(t) => (
			<div
				className="pointer-events-auto flex w-full max-w-sm items-center rounded-full bg-slate-900 shadow-lg ring-1 ring-black ring-opacity-5"
				onClick={() => {
					if (data?.clickUrl) window.location.href = data.clickUrl
				}}
			>
				<div className="w-0 flex-1 px-4 py-2">
					<div className="flex items-center">
						<div className="flex-shrink-0 pt-0.5">
							<img
								className="h-14 w-14 rounded-full"
								src={data?.imageUrl}
								alt=""
							/>
						</div>
						<div className="ml-3 flex-1">
							<p className="text-sm font-medium text-slate-100">
								{data?.title}
							</p>
							<p className="mt-1 text-sm text-slate-500">{data?.message}</p>
						</div>
					</div>
				</div>
				<div className="pr-2">
					<button
						onClick={() => toast.dismiss(t.id)}
						className="text-md flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 font-medium text-slate-300 hover:text-slate-50 focus:outline-none focus:ring-2 focus:ring-slate-50"
					>
						X
					</button>
				</div>
			</div>
		),
		{ duration: 3000, position: 'bottom-left' },
	)
}

export function toastError(message: string) {
	toast.custom(
		(t) => (
			<div className="flex w-full max-w-sm items-center justify-between rounded-full bg-slate-900 px-6 py-4 text-slate-50 shadow-md">
				<div className="flex items-center">
					<XCircleIcon className="h-6 w-6 text-red-400" />
					<p className="mx-3">{message}</p>
				</div>
				<div>
					<button className="text-slate-50" onClick={() => toast.dismiss(t.id)}>
						X
					</button>
				</div>
			</div>
		),
		{ duration: 2000, position: 'bottom-left' },
	)
}

export function toastSuccess(message: string) {
	toast.custom(
		(t) => (
			<div className="flex w-full max-w-sm items-center justify-between rounded-full bg-slate-900 px-6 py-4 text-slate-50 shadow-md">
				<div className="flex items-center">
					<CheckCircleIcon className="h-6 w-6 text-green-400" />
					<p className="mx-3">{message}</p>
				</div>
				<div>
					<button className="text-slate-50" onClick={() => toast.dismiss(t.id)}>
						X
					</button>
				</div>
			</div>
		),
		{ duration: 2000, position: 'bottom-left' },
	)
}

export function useToastFromSSE(props: { id: Profile['id']; sseUrl: string }) {
	let notify = useEventSource(props.sseUrl, {
		event: eventName_notify(props.id),
	})

	useEffect(() => {
		if (notify) {
			if (window.location.href.includes('/messages/')) return

			try {
				let eventData = JSON.parse(notify) as NotifyData

				if (eventData.variant === 'info') {
					toastInfo(eventData)
				} else if (eventData.variant === 'success') {
					toastSuccess(
						eventData.message.length > 0
							? `${eventData.title} - ${eventData.message}`
							: eventData.title,
					)
				}
			} catch (error) {
				console.error(error)
			}
		}
	}, [notify])
}

export function CustomToaster() {
	return (
		<Toaster>
			{(t) => (
				<Transition
					appear
					show={t.visible}
					className="transform rounded p-4 shadow-lg"
					enter="transition-all duration-150"
					enterFrom="opacity-0 scale-50"
					enterTo="opacity-100 scale-100"
					leave="transition-all duration-150"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-75"
				>
					{resolveValue(t.message, t)}
				</Transition>
			)}
		</Toaster>
	)
}
